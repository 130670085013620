html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
    background:$dark-grey;
    overflow-x:hidden;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}


body {
    font-family: 'Lato', sans-serif;
    font-size:0.625em;
}

.html {
    &--font-small {
        font-size: 1em;
    }
    &--font-medium {
        font-size: 1.5em;
    }
    &--font-large {
        font-size: 2em;
    }
}


.main-photo{
    background: url('/media/default/tlo.jpg') no-repeat center center;
    height:523px;
    width:100%;
    background-size:cover;
    padding-top:30px;
    text-align:center;
    @media only screen and (max-width: 1023px) {
        background: url('/css/frontend/bg-small.jpg') center center;
        height:350px;
        width:100%;
    }

}

.light-red{
    background:$light-red;

}



.content-header{
    float:left;
    font-size:2em;
    line-height:1.4em;
    padding:10px 0 0 0;
    &--white{
        color:white;
    }
    &--fixed-size{
        width:235px;
        float:left;
    }
}
.get-code{
    label{
        font-size:2em;
        line-height:1.4em;
        padding:30px 0;
        color:white;
    }
    input{
        display:block;
        font-size:2.2em;
        height:64px;
        text-align:center;
        border:none;
        -webkit-box-shadow: 1px 1px 4px 0 #595959;
        box-shadow: 1px 1px 4px 0 #595959;
        width:100%;
        max-width:284px;
        margin:0 auto;
        margin-top:-20px;
        
        text-transform:uppercase;
    }
}

.question{
    position:relative;
    padding:20px 20px 10px 20px;
    &__image{
        width:100%;
        max-width:284px;
        margin:20px auto;
        img{
            width:100%;
        }
    }
    &--green{
        background:$green;
    }
    &--white{
        background:white;
    }
    &__title{
        color:white;
        float:left;
        margin-left:3px;
        max-width:250px;
        


        &--big{
            font-size:2em;
        }
        &--small{
            font-size:1.4em;
            margin-top:10px;
        }
        &--normal{
            color:$blue;
            font-size:1.4em;

        }

    }
    &__body{
        margin-left:10px;
        color:black;
        font-size:2em;

    }

    &__icon{
        padding:5px 0;
        padding-left:65px;
        position:relative;

        &::before{
            content:'';
            display:block;
            position:absolute;
            left:0;
            top:0;
            bottom:0;
            margin:auto;
            @extend .sprite;
            @extend .sprite-ok;

        }
    }
}

.answer{
    margin-bottom:40px;


    &__item{
        width:100%;
        float:left;
        background:white;
        margin-top:20px;
        margin-left:10px;
        -webkit-box-shadow: 1px 1px 4px 0 #595959;
        box-shadow: 1px 1px 4px 0 #595959;
        position:relative;
        padding:20px;
        padding-left:80px;
        cursor:pointer;


        &:hover,&:focus{
            text-decoration:none;
        }

        &:hover{
            .answer__lp{
                background:$red;
                border-color:$red;
            }
        }


    }
    &__lp{
       position:absolute;
        left:0;
        top:0;
        bottom:0;
        margin:auto;
            text-align: center;
        background:$blue;
        color:white;
        font-size:2.5em;
        width:65px;
        border:1px solid $blue;



    }
        &__key{
        color:white;
        font-size:1em;
        position:absolute;
        left:0;
        right:0;
        top:0;
        bottom:0;
        margin:auto;
        height: 1.1em;
        text-align:center;
        }

    &__value{
        word-break: break-word;
        color:black;
        font-size:1.6em;
      
      

    }
    &__rules{
        a{
            display:block;
            width:100%;
            text-align:center;
            font-size:1.4em;
            color:black;
            text-decoration:underline;
            margin:20px auto;
            float:left;
        }
    }
}

.fail{
    padding:50px 0;
    &__text{
        font-size:2em;
        color:white;
        margin-left:65px;
        display:block;
        margin-top:10px;
        &--big{
            font-size:48px;
            //margin-bottom:30px;
            position:relative;
            display: flex;
          
           

            &::before{
                content:'';
                display:block;
                @extend .sprite;
                @extend .sprite-alert;
                position:absolute;
                top:0;
                left:-65px;
                margin-top:0 !important;;
            }
        }
        &--black{
            color:black;
            float:right;
            width:240px;
            margin-top:40px;
        }
    }
    .sprite-alert{
        margin-top:100px;
        float:left;
    }
}

.main-photo-bg{
    background: url('bg.png')no-repeat;
    background-size:cover;
    padding:5px 0px;
    box-shadow:0 10px 15px 0 rgba(0,0,0,0.5);
}


.button{
    display:block;
    padding:20px 10px;
    -webkit-border-radius: 10px 10px 10px 10px;
    border-radius: 10px 10px 10px 10px;
    color:white;
    font-size:1.7em;
    width:100%;
    margin:15px auto;
    border:none;
    text-transform:uppercase;
    font-weight:bold;
    cursor:pointer;
    text-align:center;
    &:hover,&:focus{
        text-decoration:none !important;
    }
    

  

    &--red{
        background-color:$red;
        color:white;

        &:hover,&:focus{
            color:#fff;
        }
    }
    &--blue{
        background-color:$blue;
        padding:10px 0;

         &:hover,&:focus{
            color:#fff;
        }
    }
    &--yellow{
        background-color:$yellow;
        color:#000;

        a{
            color:#000;
            text-decoration:none !important;
        }

         &:hover,&:focus{
            color:#fff;

             a{
            color:#000;
            text-decoration:none !important;
            }
        }
        &-black{
            color:black;

        &:hover,&:focus{
            color:#000;
            text-decoration:none !important;
        }
        }
        &-list{
            font-size:1.6em;
        }
    }
    &--white{
        background-color:white;
        color:white;
        margin-top:80px;
         &:hover,&:focus{
            color:#fff;
        }
        &-black{
            color:black;
            &:hover,&:focus{
                color:#000;
                text-decoration:none  !important;
            }
        }
    }
    &--small-text{
        font-size:0.65em;
        text-transform:none;
        line-height:0.5em;
        font-weight:bold;
    }
    &--small{
        font-size:1.2em;
        padding:8px;
        color:black;
        width:54%;
        margin-top:30px;
        -webkit-border-radius: 5px 5px 5px 5px;
        border-radius: 5px 5px 5px 5px;

        &:hover,&:focus{
            color:#000;
            text-decoration:none  !important;
        }
    }
    &--very-small{
        float:right;
        font-size:0.3em;
        padding:8px;
        color:black;
        width:70px;
        margin-top:10px;
        -webkit-border-radius: 5px 5px 5px 5px;
        border-radius: 5px 5px 5px 5px;
        a{
            color:black;
        }
    }

     &--margin-bottom{
        margin-bottom:40px;
    }

    &--margin-top{
        margin-top:24px;
    }

    &--register{
        margin-top:30px;
    }

    &--confirm{
       
    }

      &--margins{
        margin:30px auto;
        margin-bottom:20px;
    }


    &--auto-width{
        width:auto;
    }
}
.button-link{
    font-size:1.4em;
    margin:5px auto 40px auto;
    display:block;
    text-align:center;
    font-weight:bold;

    &--small{
        width:80px;
        margin-top:80px;
    }
    a{
        color:black;
        text-decoration:underline;

         &:hover,&:focus{
            color:#000;
        }
    }
    &--grey{
        display:block;
        margin:20px auto 0 auto;
        color:$light-grey;
        text-transform:uppercase;
        font-size:1.2em;
        text-align:center;
        font-weight:bold;


         &:hover,&:focus{
            color:$light-grey;
        }

    }

    &--small-margin{
        margin-bottom:0;
    }

    &--age{
        margin-top:0;
        margin-bottom:30px;
    }

    &.open-age{
        margin-bottom:0;
    }


}

.modal-login, .modal-reset, .modal-rules, .modal-gdpr, .modal-policy, .modal-age, .modal-reset-confirm{
    display:none;
    margin:auto;
    width:100%;
    position:absolute;
    //max-width:$width;
    z-index:9;
    padding-bottom:6px;
    bottom:0;
    left:0;
    right:0;
    margin:0;



    &__header{
        font-size:3.8em;
        color:white;
        background-color:$red;
        padding:20px 20px 20px 30px;
        margin:0 -20px;
        position:relative;
        
        &--no-padding{
            padding-bottom:10px;
        }

        &--blue{
            background-color:$blue;
            font-size:2.8em;
        }
        &--popup{
            margin:0 -15px;
        }
        &--small{
            font-size:15px
        }

        &--no-margin{
          margin: 0 -30px
        }
    }

    &__header-container{
        max-width:315px;;
        margin:0 auto;
        position:relative;
    }


}

.modal-rules, .modal-gdpr, .modal-policy, .modal-age{
   top:0;
   padding:10px 0;
   position:fixed;
   overflow-y:scroll;
   
   
    &__content{
        font-size:1.4em;
        line-height:1.4em;
        max-height:100%;
        height: 100%;
        top: 50px;
        padding:20px;
        margin:0 -20px;
        background:#fff; 
    }
    .sprite-cancel{
        
       position:absolute;
       top:0;
       bottom:0;
       right:10px;
       margin:auto;
    }
}
.modal-reset-confirm{
    display:block;
}


.m1{
  
}

.m2{
    width:85%;
    max-width:900px;
    margin:0 auto;
}

.d1 {
    height: 600px;
}
.d2 {
    height: 100%;
}
.d3 {
    height:100%;
    position: relative;
}
.d3-1 {
    min-height:30px;
}
.d3-2{
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
    padding-top: 30px;
    width: 100%;
    position: absolute;
    top: 40px;
}
.d4
{
    position: relative;
    max-height: 100%;
    overflow: auto;
}
.d5 {
}




label{
    font-size:1.6em;
    color:$blue;
    margin:10px 0;
    font-weight:bold;

}
.agree{
    display: table-cell;
    font-weight: 400;
    color:black;
    font-size:1.2em
}
.help-text{
    display:block;
    font-size:1.2em;
    color:$grey;
    margin-top:8px;
}
input{
    border:1px solid $light-grey;
    color:black;
}

.register {
    &__form {
        margin-top: 20px;
        margin-bottom:50px;
    }
    &__header {
        font-size:1.6em;
        color:$blue;
        margin:10px 0;
        font-weight:bold;
        &--black{
            color:black;
        }
        &--thin{
            font-weight:normal;
            line-height:1.2em;
            background:white;
        }
    }

     &__required{
        color:$grey;
        float:right;
           font-size: 1.2em;

    }
}
.help-block{
    font-size:1.2em;
    font-weight:bold;

    &--margin{
        margin-top:0;
        margin-bottom:20px;
    }
}
.circle{
    position:relative;
    display:block;
    height: 44px;
    width: 44px;
    border-radius: 50%;
    margin:10px auto 0 auto;
    .points{
        font-size:12px;
        color:grey;
        position:absolute;
            top: 50px;
    left: 15px;
    }
    .my-account{
        font-size:10px;
        color:grey;
        position:absolute;
        top:60px;
        left:30px;
    }
    &--red{
        background:$red;
        .big{
            position:absolute;
            top:-3px;
            font-size:30px;
            color:white;
            margin:10px 13px;
        }
        .small{
            font-size:16px;
            color:black;
            position:absolute;
            top: 20px;
            left: 47px;
        }
    }
    &--black{
        background:black;
        cursor:pointer;
    }
}
.no-padding{
    padding:0!important;
}
.no-padding-top{
    padding-top:0px!important;
}

.error {
    &__box{
        display:block;
        padding:20px 30px;
        width:360px;
        margin:0 auto;

        &--noPadding{
            padding-top:0;
            padding-left:0;
        }


            a{
                display:block;
                text-decoration: underline;
                color: #000;

            }

        }
    &__text{
        color:white;
        font-size:1.8em;
        width:100%;
        padding-left:70px;
        font-weight:bold;
        line-height:140%;
        display:block;
        position:relative;

        &::before{
            content:'';
            display:block;
            @extend .sprite;
            @extend .sprite-error;
            position:absolute;
            left:0;
            top:0;
            bottom:0;
            margin:auto;
        }
    }
}

.success{
    &__box{
        display:block;
        padding:20px 0;
        width:100%;
        float:left;

        &--noPadding{
            padding-top:0;
        }

        &--padding{
            float:none;
            padding-top:50px;
        }

        &--no-padding-top{
            padding-top:0;
        }
    }
    &__text{
        color:white;
        font-size:2em;
        float:left;
        display:block;
        margin:0 0 0 20px;
        line-height:1.2em;
        position:relative;
        padding-left:70px;


        &::before{
            content:'';
            display:block;
            @extend .sprite;
            @extend  .sprite-success;
            position:absolute;
            left:0;
            top:0;
            bottom:0;
            margin:auto;

        }

        &--large{
            font-size:3.5em;
        }
    }
    &__desc{
        font-size:1.6em;
        margin-top:20px;
        line-height:1.4em;
        margin-bottom:40px;
        &--black{
            color:black;

        }
        &--white{
            color:white;
        }
        
        &--center{
            text-align:center;
            margin-top:20px;
            margin-bottom:10px;
        }
    }

}

.continue-trail{
    display:block;
    background:$yellow;
    width:100%;
    min-height:68px;
    position:fixed;
    bottom:0;
    left:0;
    font-size:1.8em;
    color:black;
    text-align:center;
    text-transform:uppercase;
    padding:25px;
    font-weight:bold;
}

.separator-white{
    border-top:1px solid white;
    width:100%;

    margin:0 auto
}
.account{
    background:$blue;


    &__separator{
        width:100%;
        border-top:1px solid white;
        height:1px;
    }

    &--white{
        background:white;
    }
    &__item{
        padding:20px 0 20px 0;
        position:relative;
        cursor:pointer;
                
               

        &--white{
            background:white;
        }

        &--padding{
            padding-bottom:60px;
        }

        hr{
            margin:30px 0;
        }
    }
    &__text{
        font-size:2.2em;
        color:white;
        margin-left:12px;
        outline:none;
    }
    &__remove{
        a{
            display:block;
            width:100%;
            text-align:center;
            font-size:1.4em;
            color:black;
            text-decoration:underline;
        }

        &--left{
            a{
                text-align:left;
            }
        }
    }
    &__link{
        width:100%;
        text-decoration:none;

        &:hover,&:focus{
            text-decoration:none;
        }

    }

    &__points{
        width:100%;
        padding:30px 0;
                
                &--no-padding{
                    padding-top:0;
                }

        &-name{
            color:$blue;
            font-size:2.5em;
            color:black;
            padding-bottom:5px;
            border-bottom:1px solid black;
            margin-bottom:40px;
            font-weight:bold;
        }
        &-text {
            float:left;
            font-size: 1.6em;
            color: $blue;
            width:50%;
            margin-left:10px;
            font-weight:bold;
            outline:none;
            span{
                display:block;
                color:black;
                padding:20px 0;
            }
        }
        &-text-large{
            width:100%;
            color:black;
            margin:20px 20px 20px 12px;
            &-red{
                color:$red;
            }
            span{
                color:$red;
                display:inline;
            }
        }
        &-numbers{
            float:right;
            width:45%
        }
        &__separator{
            width:100%;
            border-top:1px solid grey;
            height:1px;
        }
        .big{
            font-size:4em;
            color:$red;
            margin:10px 5px;
            font-weight:bold;
        }
        .small{
            font-size:2em;
            color:black;
            font-weight:bold;
        }
        &-code{
            background:$red;
            -webkit-border-radius: 10px 10px 10px 10px;
            border-radius: 10px 10px 10px 10px;
            color:white;
            font-size:4.8em;
            width:80%;
            margin:0 auto;
            padding:10px;
            text-align:center;
            letter-spacing: 10px;
            font-weight:bold;

        }
    }
}
.open-personal, .open-subusers{
    display:none;
}


    footer{
        background:$dark-grey;
        //padding-bottom:30px;
        .footer-link{
            display:block;
            text-align:center;
            font-size:1.6em;
            margin:20px auto;
            a{
                color:white;
            }
        }
        .footer-copyright{
            font-size:1.2em;
            color:$grey;
            margin:50px auto 0 auto;
            display:block;
            text-align:center;

            &--created{
                margin:20px auto 0 auto;

            }
        }
       
    }
    .clear {
        clear: both;
    }
    
.date{
  display:block;
  width:100%;
  padding:4px 0;
  font-size:1.4em;
  color:black;
  &--bold{
    font-weight:bold;
    margin:10px 0;
  }
  &--link{
    font-size:1.6em;
    font-weight:bold;
    color:$blue!important;
    padding:4px 0;
  }
}


.header{
    &--center{
        text-align:center;
    }

    &--bold{
        font-weight:bold;
    }
}

select{
    margin-bottom:8px;
    outline:none !important;
    border:0 !important;
    box-shadow:2px 2px 5px 0 rgba(0,0,0,0.3) !important;
    -webkit-box-shadow:2px 2px 5px 0 rgba(0,0,0,0.3) !important;
    -mozbox-shadow:2px 2px 5px 0 rgba(0,0,0,0.3) !important;
    -o-box-shadow:2px 2px 5px 0 rgba(0,0,0,0.3) !important;
    -ms-box-shadow:2px 2px 5px 0 rgba(0,0,0,0.3) !important;
}


input[type="text"],input[type="password"],input[type="email"]{
     outline:none !important;
    border:0;
    box-shadow:2px 2px 5px 0 rgba(0,0,0,0.3) !important;
    -webkit-box-shadow:2px 2px 5px 0 rgba(0,0,0,0.3) !important;
    -moz-box-shadow:2px 2px 5px 0 rgba(0,0,0,0.3) !important;
    -ms-box-shadow:2px 2px 5px 0 rgba(0,0,0,0.3) !important;
    -o-box-shadow:2px 2px 5px 0 rgba(0,0,0,0.3) !important;
    -webkit-transition: none  !important;
       -moz-transition: none  !important;
        -ms-transition: none  !important;
         -o-transition: none  !important;
            transition: none  !important;

    &:focus{
        border:2px solid #DD1E35 !important;
    }
}


.return-link{
    color:$light-grey;
    text-decoration:underline;
    font-weight:bold;
    font-size: 1.4em;
    display:block;
    margin:0 auto;
    text-align: center;
    margin-bottom:30px;


    &:hover,&:hover{
        color:$light-grey;
    }

    &--margin{
        margin-top:33px
    }
    
   &__text{
      position:relative;
    }

    &__arrow{
        display:block;
        position:absolute;
        width:21px;
        height:2px;
        background:$light-grey;
        left:-27px;
        top:0;
        bottom:0;
        margin:auto;

        &::before{
            display:block;
            content:'';
            position:absolute;
            top:-3px;
            left:0;
            width:10px;
            height:2px;
            background:$light-grey;
            -webkit-transform: rotate(-35deg);
               -moz-transform: rotate(-35deg);
                -ms-transform: rotate(-35deg);
                 -o-transform: rotate(-35deg);
                    transform: rotate(-35deg);
        }

        &::after{
            display:block;
            content:'';
            position:absolute;
            top:3px;
            left:0;
            width:10px;
            height:2px;
            background:$light-grey;
            -webkit-transform: rotate(35deg);
               -moz-transform: rotate(35deg);
                -ms-transform: rotate(35deg);
                 -o-transform: rotate(35deg);
                    transform: rotate(35deg);
        }
    }

}

label{
    cursor:pointer;
}

.checkbox{
  float:left;
  opacity:0;
  margin:0 !important;

}

.checkbox-view{
position:relative;
float:left;
width:19px;
height:19px;
background:#fff;
    box-shadow:2px 2px 5px 0 rgba(0,0,0,0.3) !important;
    -webkit-box-shadow:2px 2px 5px 0 rgba(0,0,0,0.3) !important;
    -moz-box-shadow:2px 2px 5px 0 rgba(0,0,0,0.3) !important;
    -ms-box-shadow:2px 2px 5px 0 rgba(0,0,0,0.3) !important;
    -o-box-shadow:2px 2px 5px 0 rgba(0,0,0,0.3) !important;


    &.checked{
       &::before{
            width:23px;
            height:22px;
            display:block;
            content:'';
            position:absolute;
            right:-6px;
            top:-6px;
            background:url('/media/default/checked.png')no-repeat;
       }
    }

}

.form-group{
    &--no-margin{
        margin-bottom:0;
    }

    &--margin-top{
        margin-top:30px;
    }

    &--margin-bottom{
        margin-bottom:35px;
    }
}



select.form-control{
    padding-left:3px;
    padding-right:3px;
}



.relative{
    position:relative;
}

.open-block{
    position:absolute;
    right:0;
    top:0;
    bottom:0;
    margin:auto;
}

.qr-text{
    position:relative;
    padding-right:21px;
    &::before{
        content:'';
        display:block;
        @extend .sprite;
        @extend .sprite-qr;
        position:absolute;
        right:-60px;
        top:0;
    }
}

.instruction-link{
   a{
        margin-top:0;
        margin-bottom:30px;
   }
}

.container-info{
    position:relative;
    padding-bottom:10px;
    padding-left:65px;
    font-weight:bold;
    margin-right:-20px;
      span{
          line-height:140%;
      }
        
        &::before{
            content:'';
            display:block;
            @extend .sprite;
            @extend .sprite-info;
            position:absolute;
            left:0;
            top:5px;
            
        }  
}

.question__title{
    max-width:100%;
}

.container-inner.question{
    padding-top:10px;
    padding-bottom:7px;
}

.container-alert{
  position:relative;
    padding-bottom:10px;
    padding-left:65px;
    font-weight:bold;
    margin-right:-20px;
      span{
          line-height:140%;
      }
        
        &::before{
            content:'';
            display:block;
            @extend .sprite;
            @extend .sprite-alert;
            position:absolute;
            left:0;
            top:0;
            bottom:0;
            margin:auto !important;
            filter: invert(100%);
            
        }  
}


.container-ok{
    position:relative;
    padding-left:65px;
    padding-bottom:20px;
    margin-right:-20px;

    &::before{
        content:'';
        display:block;
        @extend .sprite;
        @extend .sprite-ok;
        position:absolute;
        left:0;
        top:0;
    }
}

   

.container-success{
    position:relative;
    padding-left:65px;
    padding-bottom:20px;
    margin-right:-20px;

    &::before{
        content:'';
        display:block;
        @extend .sprite;
        @extend .sprite-success;
        position:absolute;
        left:0;
        top:0;
    }
}

.continue-trail{
    z-index:10;

    &:hover,&:focus{
        text-decoration:none;
        color:#000;
    }
}


.blur{
    position:fixed;
    top:0;
    right:0;
    left:0;
    bottom:0;
    width:100%;
    height:100%;
    background:rgba(0,0,0,0.5);
    display:none;
}


.overflow{
    overflow:hidden;
}


.red-bg{
    background:$red;
}

.modal-header-text{
    float:left;
}

.circle-big{
    width:46px;
    height:46px;
    background:#333;

    .sprite-back{
        position: relative;
        left: 1px;
        top: 1px;
    }
}

.circle-account{
    text-align:center;
    display:block;
    font-size:12px;

    .circle{
        margin-bottom:7px;
    }

    .my-account{
        color: grey;
            font-size: 12px;
    }
}

.error{
    padding-bottom:30px;
}


.previous-button{
    position:relative;
    display:block;
    padding-left:0.7em;

    &::before{
        content:'';
        display:block;
        @extend .sprite;
        @extend .sprite-arrow-left;
        position:absolute;
        left:-10px;
        top:0;
        bottom:0;
        margin:auto;
    }
}

.circle-account{
    &:hover,&:focus{
        text-decoration:none;
    }
}

.light-green-gradient--padding{
    padding:20px 0;
}

.account-white{
    background:#fff;
}


.sprite.active{
        box-shadow: 0 0 0 3px #ED174C inset;
}

.container-fluid-margin{
    margin:0 -15px !important;
}

.container__success{
    background:$green !important;
}

.container__error{
    background:$light-red !important;
}


.success-ol{
    list-style-type:decimal !important;
    font-size:20px;   
    font-size: 1.6em;
    color:#000;
    padding-left:15px !important;

    li{
        line-height:140%;
        margin-bottom:7px;
    }
}

.legend{
    cursor:auto;
    max-width:282px;
    margin:0 auto;
    margin-top:30px;
    margin-bottom:40px;
   &__header{
    font-size:2.5em;
    color:#000;
    font-weight:bold;
   }

   &__item{
    font-size:1.6em;
    border-bottom:1px solid #ccc;
    position:relative;
    padding:35px 0;

    span{
        padding-right:15px;
        max-width: 236px;
        display: inline-block;
    }
   }

   &__marker{
    position:absolute;
    right:0;
    top:15px;
   }
}

.white-background{
    background:#fff;
}

.modal-rules
{
    &__content
    {
        strong
        {
            font-weight:bold;
        }
        ol
        {
            padding-left: 3rem;
            list-style-type: decimal;
            ol{
                list-style-type: lower-alpha;
            }
        }
        ul
        {
            padding-left: 3rem;
        }
        p, ol, ul, li
        {
            padding-bottom: 10px;
        }
    }
}

.company-info {
    padding-bottom: 50px;
}

.ue-info {
    background: white;
    text-align: center;
    font-family: 'Lato', sans-serif;
    line-height: 15px;
    span {
        font-size: 11px;
    }
    img {
        margin: 0 auto;
    }
}

body > div.main-photo > a > img {
    @media only screen and (max-width: 1023px) {
        width: 120px;
    }
}

.ue-modal-info {
    &--one {
        display: inline-flex;
        justify-content: left;
    }

    &--two {
        display: inline-flex;
        justify-content: left;
    }
}