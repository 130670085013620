.wcag-bar{
  background:$dark-grey;
  padding:5px;
}
.wcag{
  float:right;
  margin-right:10px;
  width:240px;
  &__separator{
    float:left;
    display:block;
    width:35px;
    height:10px;
  }
}
.active-lang{
  margin:10px 0 0 0;
  float:left;
  &:hover{
    .select-lang{
      display:block;
      top:36px;
      padding:12px 40px 12px 6px;
      background:white;
      -webkit-box-shadow: 1px 1px 4px 0 #595959;
      box-shadow: 1px 1px 4px 0 #595959;
      width:150px;
      &__text{
        float:left;
        margin-left:15px;
        margin-top:3px;
      }
      a{
        float:left;
        display:block;
        margin:5px;
        font-size:1.6em;
        color:black;
        width:100%;
        &.active{
          opacity: 0.5;
        }
      }
    }
  }
}
.select-lang{
  display:none;
  position:absolute;
  z-index:9;

}

.hidden-link {
  line-height: 0 !important;
  font-size: 0 !important;
  color: transparent !important;
  float:left;
  overflow:hidden;
}

#skipLinks {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  list-style: none;
  a{
    display: block;
    left: -9000em;
    padding: 10px 10px 10px 10px;
    position: absolute;
    text-decoration: none;
    z-index: 200;
    width: 150px;
    min-height: 30px;
    text-align: center;
    color: #ffff00;
    font-size: 1.6em;
  }
}