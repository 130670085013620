.container{
  max-width:$width;
  padding:5px;
  &__error{
    max-width:100%;
    padding:0 -20px;
    margin-left:auto;
    margin-right:auto;
    background:$light-red;
  }
}
.container-inner{
  margin-left:auto;
  margin-right:auto;
  max-width:$width;
  padding:20px 30px 0 30px;
      
      &--blue{
        background:$blue;
      }

      &--small-padding-top{
        padding-top:10px;
      }

      &--map{
        max-width:100%;
        padding:0;
        margin-bottom:-20px;
      }

      &--white{
        background:#fff;
        padding:10px 0;
      }


  h1{
    font-size:2.2em;
    margin:auto;
    color:$blue;
    text-transform:uppercase;
    margin-bottom:20px;
  }
  &__header{
    font-size:3.8em;
    color:white;
    background-color:$blue;
    padding:20px 20px 20px 30px;
    margin:-20px -20px 0 -20px;

    &--small-font{
      font-size:3em;
    }
  }
  &__header--small{
    font-size:2em;
  }
}
.container-header{
  background:$blue;

  &--red{
    background:$red;
  }

  &--white{
    background:#fff;
  }
}