.sprite {
  background-image: url('spritesheet.png');
  background-repeat: no-repeat;
  display: block;
}

.sprite--small-font {
  width: 39px;
  height: 39px;
  background-position: -10px -10px;
}





.sprite--small-font:hover,
.sprite--small-font:active,
.sprite--medium-font:hover,
.sprite--medium-font:active,
.sprite--big-font:hover, .sprite--big-font:active,
.sprite--wcag:hover,
.sprite--wcag:active,
.sprite--normal:hover,
.sprite--normal:active{
    
    box-shadow:0 0 0 3px #ED174C inset; 
}

.sprite--medium-font {
  width: 39px;
  height: 39px;
  background-position: -69px -10px;
  margin:0 3px;
}

.sprite--big-font {
  width: 39px;
  height: 39px;
  background-position: -128px -10px;
}

.sprite-account {
  width: 44px;
  height: 44px;
  background-position: -187px -10px;
}

.sprite-alert {
  width: 55px;
  height: 55px;
  background-position: -251px -10px;
}

.sprite-arrow-left {
  width: 21px;
  height: 13px;
  background-position: -326px -10px;
  float:left;
}

.sprite-back {
  width: 44px;
  height: 44px;
  background-position: -10px -85px;
}

.sprite-bike {
  width: 47px;
  height: 30px;
  background-position: -74px -85px;
  margin:0 auto 30px auto;
}

.sprite-cs, .sprite-cz {
  width: 32px;
  height: 22px;
  background-position: -326px -43px;
  float:left;
}

.sprite-en {
  width: 32px;
  height: 22px;
  background-position: -141px -85px;
  float:left;
}

.sprite-error {
  width: 55px;
  height: 55px;
  background-position: -193px -85px;
  float:left;
  margin-right:10px;
}

.sprite-green-marker {
  width: 44px;
  height: 58px;
  background-position: -268px -85px;
}

.sprite-info {
  width: 55px;
  height: 55px;
  background-position: -10px -163px;
  float:left;
  margin-bottom:10px;
  margin-right:10px;
}

.sprite-logo {
  width: 170px;
  height: 170px;
  background-position: -85px -163px;
  margin:0 auto 0 auto;
}

.sprite-logo-small {
  width: 74px;
  height: 75px;
  background-position: -275px -163px;
  margin:0 auto;
}

.sprite-minus {
  width: 32px;
  height: 4px;
  background-position: -332px -85px;
  float:right;
  cursor:pointer;
  margin-top:14px;
}

.sprite--normal {
  width: 39px;
  height: 39px;
  background-position: -10px -258px;
}

.sprite-ok {
  width: 55px;
  height: 55px;
  background-position: -275px -258px;
  float:left;
  margin-bottom:10px;
  margin-right:10px;
}

.sprite-optimalit {
  width: 105px;
  height: 14px;
  background-position: -10px -353px;
  margin:15px auto 0 auto;
}

.sprite-pl {
  width: 32px;
  height: 22px;
  background-position: -332px -109px;
  float:left;
}

.sprite-plus {
  width: 32px;
  height: 32px;
  background-position: -275px -333px;
  float:right;
  cursor:pointer;
}

.sprite-cancel {
  width: 32px;
  height: 32px;
  background-position: -275px -333px;
  transform: rotate(45deg) scale(0.7);
  cursor:pointer;
  position:absolute;
  right:0;
  top:0;
  bottom:0;
  margin:auto;

}

.sprite-qr {
  width: 87px;
  height: 105px;
  background-position: -384px -10px;
  float:right;
  margin-top:20px;
  margin-right:-5px;
}

.sprite-red-marker {
  width: 44px;
  height: 58px;
  background-position: -384px -135px;
}

.sprite-success {
  width: 55px;
  height: 55px;
  background-position: -369px -213px;
  float:left;
  margin-top:10px;
}

.sprite--wcag {
  width: 39px;
  height: 39px;
  background-position: -350px -288px;
}

.sprite--win {
  float:left;
  display:block;
  width: 210px;
  height: 200px;
  background-image: url('win.png');
  margin:0 auto;
}
